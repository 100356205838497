import { Box, Form, FormField, TextInput, Button, Text, Select } from 'grommet'
import React, { useState } from 'react'
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { createTeam } from '../../graphql/mutations';

const CreateTeam = () => {
    const [value, setValue] = useState({ name: "", sport: "", type: "" });
    const [createdID, setCreatedID] = useState("");

    const submitTeam = async ({ name: displayName, sport, type }) => {
        type = type.replace(" ", "_");
        try {
            const { data } = await API.graphql({
                query: createTeam,
                variables: { input: { displayName, sport, type } },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            setCreatedID(data.createTeam.id);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    const sportOptions = ["Cheer", "Dance"];
    const cheerTypes = ["Group Stunt",
        "Partner Stunt", "Full Team"]
    const danceTypes = [
        "Dance Duo",
        "Full Team"
    ];

    return (
        <Box pad="large" >
            <NavLink to="/teams" style={{ color: 'inherit' }}>
                <Button primary label="Back To Teams" />
            </NavLink>

            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({ name: "", sport: "", type: "" })}
                onSubmit={() => submitTeam(value)}
            >
                <FormField required name="name" htmlFor="text-name-id" label="New Team Name">
                    <TextInput id="text-name-id" name="name" />
                </FormField>
                <FormField required name="sport" htmlFor="team-sport-id" label="Team Sport">
                    <Select
                        id="team-sport-id"
                        name="sport"
                        placeholder="Select a sport"
                        options={sportOptions}
                    />
                </FormField>
                <FormField required name="type" htmlFor="team-type-id" label="Team Type">
                    <Select
                        id="team-type-id"
                        name="type"
                        placeholder="Select team type"
                        disabled={value.sport === ""}
                        options={
                            value.sport === "Cheer" ? cheerTypes : danceTypes}
                    />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Submit" />
                    <Button type="reset" label="Reset" />
                </Box>
            </Form>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Team</Text>
                    <Text>Team ID: {createdID}</Text>
                </Box>
            )}
        </Box>
    )
}

export default CreateTeam;