import { Box, Text, Heading } from 'grommet'
import { Auth } from "aws-amplify";
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

const AdminPage = () => {

    const [user, setUser] = useState({});

    const getUser = async () => {
        try {
            const userResponse = await Auth.currentAuthenticatedUser();
            setUser(userResponse);
        }
        catch {
            setUser({});
        }
    }

    if (!user.username) {
        return (
            <AmplifyAuthenticator handleAuthStateChange={getUser} />
        )
    }

    return (
        <Box height="100vh" direction="row">
            <Box pad="medium" gap="medium">
                <Heading>Admin</Heading>
                <Link to={`/admin/createBulkMembers`}><Text>Create Bulk Members</Text></Link>
                <Link to={`/admin/createMember`}><Text>Create Member</Text></Link>
                <Link to={`/admin/createDivision`}><Text>Create Division</Text></Link>
                <Link to={`/admin/createLocation`}><Text>Create Location</Text></Link>
                <Link to={`/admin/createResult`}><Text>Create Result</Text></Link>

                <Link to={`/admin/createTeam`}><Text>Create Team</Text></Link>
                <Link to={`/admin/createCompetition`}><Text>Create Competition</Text></Link>
                <Link to={`/admin/uploadImages`}><Text>Upload Images</Text></Link>
                <AmplifySignOut handleAuthStateChange={getUser} />
            </Box>

        </Box>
    )
}

export default AdminPage;