import { API } from "aws-amplify";
import { createMember } from '../graphql/mutations';

const createMemberRequest = async (memberData) => {
    let { displayName,
        isAthlete,
        isCoach,
        isLifeMember,
        yearInducted,
        isCommitteeMember,
        committeePositionName,
        committeePositionYear,
        execPosition,
        hasAwards,
        awardName,
        awardYear,
        notes
    } = memberData;

    if (yearInducted) yearInducted = parseInt(yearInducted);
    if (committeePositionYear) committeePositionYear = parseInt(committeePositionYear);
    if (awardYear) awardYear = parseInt(awardYear);
    if (notes) notes = notes.split(', ');

    let variables = {
        input: {
            displayName,
            isAthlete,
            isCoach,
            lifeMemberStatus: {
                isLifeMember
            },
            notes
        }
    };

    if (isLifeMember) {
        variables.input.lifeMemberStatus['yearInducted'] = yearInducted;
    }

    if (isCommitteeMember) {
        variables.input["committeePositions"] = {
            displayName: committeePositionName,
            year: committeePositionYear,
            executivePosition: execPosition
        }
    }

    if (hasAwards) {
        variables.input["awards"] = {
            displayName: awardName,
            year: awardYear
        }
    }

    try {
        const { data } = await API.graphql({
            query: createMember,
            variables,
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
        return data.createMember.id;
    } catch (error) {
        console.log(`Error executing mutation: ${error}`);
        console.log(`Error executing mutation: ${JSON.stringify(error)}`);
    }
};

export default createMemberRequest;

// module.exports = {
//     createMemberRequest
// }