import { Box, Form, FormField, TextInput, Button, Text, Select } from 'grommet'
import React, { useEffect, useState } from 'react'
import { API } from "aws-amplify";
import { useParams, NavLink } from "react-router-dom";
import { createCompInstance } from '../../graphql/mutations';
import { listLocations } from '../../graphql/queries';

const CreateCompInstance = () => {
    const { compID } = useParams();
    const [value, setValue] = useState({ year: "", location: "", compDates: "" });
    const [createdID, setCreatedID] = useState("");
    const [locations, setLocations] = useState([]);

    const getLocations = async () => {
        try {
            const { data } = await API.graphql({
                query: listLocations,
            });
            setLocations(data.listLocations.items);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        const fetchData = async () => await getLocations();
        fetchData();
    }, []);

    const submitCompInstance = async ({ year, location: compInstanceLocationId, compDates }) => {
        year = parseInt(year);

        try {
            const { data } = await API.graphql({
                query: createCompInstance,
                variables: { input: { year, compDates, compInstanceLocationId, compInstanceCompetitionId: compID } },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            setCreatedID(data.createCompInstance.id);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    return (
        <Box pad="large" >
            <NavLink to={`/competitions/${compID}`}>
                <Button primary label={`Back To Competition`} />
            </NavLink>

            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({ year: "", location: "", compDates: "" })}
                onSubmit={() => submitCompInstance(value)}
            >
                <FormField required name="year" htmlFor="text-year-id" label="Competition Year">
                    <TextInput id="text-year-id" name="year" />
                </FormField>
                <FormField name="compDates" htmlFor="text-compDates-id" label="Competition Dates">
                    <TextInput id="text-compDates-id" name="compDates" />
                </FormField>
                <FormField name="location" htmlFor="comp-location-id" label="Location">
                    <Select
                        id="comp-location-id"
                        name="location"
                        placeholder="Select a location"
                        options={locations}
                        labelKey="displayName"
                        valueKey={{ key: 'id', reduce: true }}
                    />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Submit" />
                    <Button type="reset" label="Reset" />
                </Box>
            </Form>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Comp Instance</Text>
                    <Text>Instance ID: {createdID}</Text>
                </Box>
            )}
        </Box>
    )
}

export default CreateCompInstance;