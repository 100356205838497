import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify"
import { Search } from 'grommet-icons';
import { Box, Text, TextInput } from 'grommet';
import { searchMembers } from '../graphql/custom-queries';
import MoncadSpinner from "./MoncadSpinner";

const MemberSearch = ({ isAthlete, isCoach, updateMembers }) => {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [suggestions, setSuggestions] = useState([]);
    const [members, setMembers] = useState([]);

    // Needs to take in searchValue not use regular value, as doesn't update correctly when typing
    const formatSuggestions = (searchValue) =>
        members
            .filter(({ displayName }) => displayName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
            .map(({ displayName, id }, index, list) => ({
                label: (
                    <Box
                        direction="row"
                        align="center"
                        gap="small"
                        border={index < list.length - 1 ? 'bottom' : undefined}
                        pad="small"
                    >
                        {/* TODO: Consider re-adding photos later */}
                        {/* <Image width="48px" src={imageUrl} style={{ borderRadius: '100%' }} /> */}
                        <Text>
                            <strong>{displayName}</strong>
                        </Text>
                    </Box>
                ),
                displayName,
                id
            }));

    useEffect(() => {
        const getAllMembers = async () => {
            try {
                const { data } = await API.graphql({
                    query: searchMembers,
                    variables: isCoach ? {
                        filter: {
                            isCoach: { eq: true }
                        }
                    } : {},
                    authMode: "AMAZON_COGNITO_USER_POOLS"
                });

                let memberData = data.listMembers.items;
                let nextToken = data.listMembers.nextToken;

                while (nextToken) {
                    let variables = {
                        nextToken
                    };
                    if (isCoach) variables = {
                        filter: {
                            isCoach: { eq: true }
                        },
                        nextToken
                    }
                    const { data: nextMembers } = await API.graphql({
                        query: searchMembers,
                        variables,
                        authMode: "AMAZON_COGNITO_USER_POOLS"
                    });
                    nextToken = nextMembers.listMembers.nextToken;
                    memberData.push(...nextMembers.listMembers.items);
                }

                setMembers(memberData);
                setIsLoading(false);
            } catch (error) {
                console.log(`Error executing mutation: ${error}`);
                console.log(`Error executing mutation: ${JSON.stringify(error)}`);
            }
        }
        getAllMembers();
    }, [])

    const onChange = (event) => {
        const { value: newValue } = event.target;
        setValue(newValue);

        if (!newValue.trim()) {
            setSuggestions([]);
        } else {
            setSuggestions(formatSuggestions(newValue));
        }
    }

    const onSuggestionSelect = (event) => {
        const { displayName, id } = event.suggestion;
        updateMembers({ displayName, id });
        setValue("");
    }

    return isLoading ? <MoncadSpinner /> :
        <TextInput
            icon={<Search />}
            placeholder={isAthlete ? "Search for an athlete" : isCoach ? "Search for a coach" : "Search for member"}
            suggestions={suggestions}
            value={value}
            onChange={onChange}
            onSuggestionSelect={onSuggestionSelect}
        />
};

export default MemberSearch;