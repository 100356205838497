import React from "react";
import { Box, Layer, Text } from "grommet";
import { StatusGood, StatusWarning } from "grommet-icons"

const Popup = ({ text, success = true }) => {
    return (<Layer
        position="bottom"
        modal={false}
        margin={{ vertical: 'medium', horizontal: 'small' }}
        responsive={false}
        plain
    >
        <Box
            align="center"
            direction="row"
            gap="small"
            justify="between"
            round="medium"
            elevation="medium"
            pad={{ vertical: 'xsmall', horizontal: 'small' }}
            background={success ? "status-ok" : "status-critical"}
        >
            <Box align="center" direction="row" gap="xsmall">
                {success ? <StatusGood /> : <StatusWarning />}
                <Text>
                    {text}
                </Text>
            </Box>
        </Box>
    </Layer>)
}

export default Popup;