import { Box, Form, FormField, Button, Text, Select } from 'grommet'
import React, { useEffect, useState } from 'react'
import { API } from "aws-amplify";
import { useParams, NavLink } from "react-router-dom";
import { createCompTeam } from '../../graphql/mutations';
import { getCompTeamData } from '../../graphql/custom-queries';

const CreateCompTeam = () => {
    const { teamID } = useParams();
    const [value, setValue] = useState({ competition: "", division: "", result: "", grandChamp: "" });
    const [createdID, setCreatedID] = useState("");
    const [comps, setComps] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [results, setResults] = useState([]);
    const grandChampOptions = [{
        value: true,
        displayName: "True"
    },
    {
        value: false,
        displayName: "False"
    }];

    const createCompNames = (compData) => {
        return compData.map(({ id, competition, year }) => {
            return {
                id,
                displayName: `${competition.displayName} ${year}`
            }
        })
    }

    const getData = async () => {
        try {
            const { data } = await API.graphql({
                query: getCompTeamData,
            });
            const names = createCompNames(data.listCompInstances.items);
            setComps(names);
            setDivisions(data.listDivisions.items);
            setResults(data.listCompResults.items);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        const fetchData = async () => await getData();
        fetchData();
    }, []);

    const submitCompTeam = async ({ competition, division, result, grandChamp }) => {
        try {
            const variables = {
                input: {
                    compTeamTeamId: teamID,
                    compTeamCompetitionId: competition,
                    compTeamDivisionId: division,
                    resultID: result,
                    grandChamp
                }
            }
            const { data } = await API.graphql({
                query: createCompTeam,
                variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            setCreatedID(data.createCompTeam.id);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    return (
        <Box pad="large" >
            <NavLink to={`/teams/${teamID}`}>
                <Button primary label={`Back To Team`} />
            </NavLink>

            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({ competition: "", division: "", result: "", grandChamp: "" })}
                onSubmit={() => submitCompTeam(value)}
            >
                <FormField required name="competition" htmlFor="select-competition-id" label="Competition">
                    <Select
                        id="select-competition-id"
                        name="competition"
                        placeholder="Select a competition"
                        options={comps}
                        labelKey="displayName"
                        valueKey={{ key: 'id', reduce: true }}
                    />
                </FormField>
                <FormField required name="division" htmlFor="select-division-id" label="Team Division">
                    <Select
                        id="select-division-id"
                        name="division"
                        placeholder="Select a division"
                        options={divisions}
                        labelKey="displayName"
                        valueKey={{ key: 'id', reduce: true }}
                    />
                </FormField>
                <FormField name="result" htmlFor="select-result-id" label="Team Result">
                    <Select
                        id="select-result-id"
                        name="result"
                        placeholder="Select a result"
                        options={results}
                        labelKey="displayName"
                        valueKey={{ key: 'id', reduce: true }}
                    />
                </FormField>
                <FormField name="grandChamp" htmlFor="select-grandChamp-id" label="Grand Champion">
                    <Select
                        id="select-grandChamp-id"
                        name="grandChamp"
                        placeholder="Select an outcome"
                        options={grandChampOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Submit" />
                    <Button type="reset" label="Reset" />
                </Box>
            </Form>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Comp Team</Text>
                    <Text>Team ID: {createdID}</Text>
                </Box>
            )}
        </Box>
    )
}

export default CreateCompTeam;