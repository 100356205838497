import React, { useEffect, useState } from "react";
import { Box, Form, Select, FormField, Layer, Spinner, Text } from "grommet";
import { v4 as uuid } from "uuid"
import { API, Storage } from "aws-amplify";
import { listCompInstances, getTeamsForInstance } from "../../graphql/custom-queries";
import { createMedia } from "../../graphql/mutations"
import Dropzone from 'react-dropzone';
import MoncadSpinner from "../../components/MoncadSpinner";
import Popup from "../../components/Popup";
import { Upload } from "grommet-icons";

const UploadImages = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    // const [progressPercentage, setProgressPercentage] = useState(0);
    const [currentImage, setCurrentImage] = useState("");
    const [totalImages, setTotalImages] = useState("");
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);
    const [value, setValue] = useState({ compInstance: "", compTeam: "" });
    const [compInstances, setCompInstances] = useState([]);
    const [compTeams, setCompTeams] = useState([]);

    const createCompNames = (compData) => {
        return compData.map(({ id, competition, year }) => {
            return {
                id,
                displayName: `${competition.displayName} ${year}`
            }
        })
    };

    const createTeamNames = (teamData) => {
        return teamData.map(({ id, team }) => {
            return {
                id,
                displayName: team.displayName
            };
        });
    };

    useEffect(() => {
        const getCompInstances = async () => {
            try {
                const { data } = await API.graphql({
                    query: listCompInstances
                });
                const compNames = createCompNames(data.listCompInstances.items);
                setCompInstances(compNames);
                setIsLoading(false);
            } catch (error) {
                console.log(`Error executing query: ${error}`);
                console.log(`Error executing query: ${JSON.stringify(error)}`);
            }
        }
        getCompInstances();
    }, []);

    useEffect(() => {
        const getCompTeams = async () => {
            setIsLoading(true);
            if (!value.compInstance) {
                setIsLoading(false);
                return;
            }
            try {
                const { data } = await API.graphql({
                    query: getTeamsForInstance,
                    variables: { id: value.compInstance },
                });
                const teamNames = createTeamNames(data.getCompInstance.teams.items);
                setCompTeams(teamNames);
                setIsLoading(false);
            } catch (error) {
                console.log(`Error executing query: ${error}`);
                console.log(`Error executing query: ${JSON.stringify(error)}`);
            }
        }
        getCompTeams();
    }, [value.compInstance]);

    const onSuccess = () => {
        setIsSuccessful(true);
    }

    const onFail = () => {
        setIsError(true);
    }

    const addMediaToDB = async mediaData => {

        console.log(`Creating media...`);

        try {
            const data = await API.graphql({
                query: createMedia,
                variables: { input: mediaData },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            console.log(`Executed mutation,   data:${JSON.stringify(data)}`);
        } catch (error) {
            onFail();
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    const uploadFiles = async acceptedFiles => {
        setIsUploading(true);
        setTotalImages(acceptedFiles.length);

        try {
            for (const file of acceptedFiles) {
                if (!file.type.includes('video') && !file.type.includes('image') && !file.type.includes('audio')) {
                    // TODO: display error properly
                    alert('One or more files are not an accepted file type');
                    setIsUploading(false);
                    return;
                }
            }

            // TODO: Include upload progress?
            for (const [index, file] of acceptedFiles.entries()) {
                console.log(`uploading ${file.name}`)
                setCurrentImage(index + 1);
                // setProgressPercentage(parseInt((currentImage / totalImages) * 100));
                const filePathValue = uuid();
                const fileResponse = await Storage.put(filePathValue, file);

                let type;
                if (file.type.includes('video')) {
                    type = 'video';
                }
                else if (file.type.includes('image')) {
                    type = "photo"
                }
                else if (file.type.includes('audio')) {
                    type = "music"
                }

                const mediaData = {
                    type,
                    mediaCompId: value.compInstance,
                    mediaTeamId: value.compTeam,
                    id: fileResponse.key,
                    originalName: file.name
                }

                await addMediaToDB(mediaData);
            }
            setIsUploading(false);
            onSuccess();
        }
        catch (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            setIsUploading(false);
            onFail();
        }
    }

    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    return (
        <Box pad="medium">
            {isLoading ? <MoncadSpinner /> :
                <>
                    {isUploading && (
                        <Layer>
                            <Box
                                align="center"
                                justify="center"
                                gap="small"
                                alignSelf="center"
                                pad="large"
                            >
                                <Spinner size="medium" />
                                <Box direction="row" gap="small">
                                    {/* TODO: Include meter to show progress */}
                                    {/* <Meter background="light-4" value={progressPercentage} /> */}
                                    <Text>Uploading image {currentImage} of {totalImages}</Text>
                                </Box>
                            </Box>
                        </Layer>
                    )}
                    {isSuccessful && (
                        <Popup text="Successfully Uploaded" success={true} />
                    )}
                    {isError && (
                        <Popup text="An error occurred" success={false} />
                    )}
                    <Form
                        value={value}
                        onChange={nextValue => setValue(nextValue)}
                        onReset={() => setValue({ compInstance: "", compTeam: "" })}
                    >
                        <FormField required name="compInstance" htmlFor="select-compinstance-id" label="Competition">
                            <Select
                                id="select-compinstance-id"
                                name="compInstance"
                                placeholder="Select a Competition"
                                options={compInstances}
                                labelKey="displayName"
                                valueKey={{ key: 'id', reduce: true }}
                            />
                        </FormField>
                        <FormField required name="compTeam" htmlFor="select-compteam-id" label="Team">
                            <Select
                                disabled={!value.compInstance}
                                id="select-compteam-id"
                                name="compTeam"
                                placeholder="Select a Team"
                                options={compTeams}
                                labelKey="displayName"
                                valueKey={{ key: 'id', reduce: true }}
                            />
                        </FormField>
                    </Form>
                    <Dropzone accept={['image/*', 'video/*', 'audio/*']} disabled={!(value.compInstance && value.compTeam)} onDrop={uploadFiles}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <Upload />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </>
            }
        </Box>
    );
}

export default UploadImages;
