import React from 'react'
import { Card, Text } from 'grommet';
import { useHistory } from "react-router-dom";

const CompCard = (compDetails) => {
    const history = useHistory();

    const navigateToComp = (id) => {
        history.push(`/competitions/${id}`);
    }

    const { id, displayName, year } = compDetails.props;

    return (
        <Card onClick={() => navigateToComp(id)} justify="center" align="center" width="medium" pad="medium" background={{ "color": "background" }}>
            <Text textAlign="center" weight="bold" size="large">{displayName}</Text>
            <Text textAlign="center" color="grey">{year}</Text>
        </Card>
    )
};

export default CompCard;