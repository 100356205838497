import React from 'react'
import { Card, Text } from 'grommet';
import { useHistory } from "react-router-dom";

const CompTeamCard = ({ id, displayName, year, division }) => {
    const history = useHistory();

    const navigateToCompTeam = (id) => {
        history.push(`/competitionTeam/${id}`);
    }

    return (
        <Card onClick={() => navigateToCompTeam(id)} justify="center" align="center" width="medium" pad="medium" background={{ "color": "background" }}>
            <Text textAlign="center" weight="bold" size="large">{displayName}</Text>
            <Text textAlign="center" color="grey">{year}</Text>
            <Text textAlign="center" color="grey">{division}</Text>
        </Card>
    )
};

export default CompTeamCard;