import { Box, Form, FormField, TextInput, Button, Text, Select, TextArea, Spinner, Layer } from 'grommet'
import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import Popup from "../../components/Popup";
import createMemberRequest from "../../utils/createMemberRequest";

const CreateMember = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);

    const [value, setValue] = useState({
        displayName: "",
        isAthlete: "",
        isCoach: "",
        isLifeMember: "",
        yearInducted: "",
        isCommitteeMember: "",
        committeePositionName: "",
        committeePositionYear: "",
        execPosition: "",
        hasAwards: "",
        awardName: "",
        awardYear: "",
        notes: ""
    });
    const [createdID, setCreatedID] = useState("");

    const boolOptions = [{
        value: true,
        displayName: "Yes"
    },
    {
        value: false,
        displayName: "No"
    }];

    const handleComplete = () => {
        setIsSuccessful(true);
        setTimeout(() => {
            setIsSuccessful(false);
        }, 3000);
    }

    const handleError = () => {
        setIsError(true);
        setTimeout(() => {
            setIsError(false);
        }, 3000);
    }

    const submitMember = async (memberData) => {
        setIsUploading(true);
        try {
            const memberID = await createMemberRequest(memberData);
            setCreatedID(memberID);
            setIsUploading(false);
            handleComplete();
        }
        catch (error) {
            console.error(error);
            setIsUploading(false)
            handleError();
        }
    }

    return (
        <Box pad="large">
            {isUploading && (
                <Layer>
                    <Box
                        align="center"
                        justify="center"
                        gap="small"
                        direction="row"
                        alignSelf="center"
                        pad="large"
                    >
                        <Spinner />
                        <Text>Uploading...</Text>
                    </Box>
                </Layer>
            )}
            <NavLink to="/members">
                <Button primary label="Back To Members" />
            </NavLink>
            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({
                    displayName: "",
                    isAthlete: "",
                    isCoach: "",
                    isLifeMember: "",
                    yearInducted: "",
                    isCommitteeMember: "",
                    committeePositionName: "",
                    committeePositionYear: "",
                    execPosition: "",
                    hasAwards: "",
                    awardName: "",
                    awardYear: "",
                    notes: ""
                })}
                onSubmit={() => submitMember(value)}
            >
                <FormField required name="displayName" htmlFor="text-name-id" label="Member Name">
                    <TextInput id="text-name-id" name="displayName" />
                </FormField>
                <FormField name="isAthlete" htmlFor="select-isAthlete-id" label="Is this person an athlete?">
                    <Select
                        id="select-isAthlete-id"
                        name="isAthlete"
                        placeholder="Select an option"
                        options={boolOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                <FormField name="isCoach" htmlFor="select-isCoach-id" label="Is this person a coach?">
                    <Select
                        id="select-isCoach-id"
                        name="isCoach"
                        placeholder="Select an option"
                        options={boolOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                <FormField name="isLifeMember" htmlFor="select-isLifeMember-id" label="Is this person a Life Member?">
                    <Select
                        id="select-isLifeMember-id"
                        name="isLifeMember"
                        placeholder="Select an option"
                        options={boolOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                {value.isLifeMember && (
                    <FormField required={value.isLifeMember} name="yearInducted" htmlFor="text-yearInducted-id" label="Year Inducted">
                        <TextInput id="text-yearInducted-id" name="yearInducted" />
                    </FormField>
                )}
                <FormField name="isCommitteeMember" htmlFor="select-isCommitteeMember-id" label="Is this person a Committee Member?">
                    <Select
                        id="select-isCommitteeMember-id"
                        name="isCommitteeMember"
                        placeholder="Select an option"
                        options={boolOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                {value.isCommitteeMember && (
                    <>
                        <FormField required={value.isCommitteeMember} name="committeePositionName" htmlFor="text-committeePositionName-id" label="Position Name">
                            <TextInput id="text-committeePositionName-id" name="committeePositionName" />
                        </FormField>
                        <FormField required={value.isCommitteeMember} name="committeePositionYear" htmlFor="text-committeePositionYear-id" label="Position Year">
                            <TextInput id="text-committeePositionYear-id" name="committeePositionYear" />
                        </FormField>
                        <FormField name="execPosition" htmlFor="select-execPosition-id" label="Was this an Exec position?">
                            <Select
                                id="select-execPosition-id"
                                name="execPosition"
                                placeholder="Select an option"
                                options={boolOptions}
                                labelKey="displayName"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                        </FormField>
                    </>
                )}
                <FormField name="hasAwards" htmlFor="select-hasAwards-id" label="Has this person won any Club awards?">
                    <Select
                        id="select-hasAwards-id"
                        name="hasAwards"
                        placeholder="Select an option"
                        options={boolOptions}
                        labelKey="displayName"
                        valueKey={{ key: 'value', reduce: true }}
                    />
                </FormField>
                {value.hasAwards && (
                    <>
                        <FormField required={value.isCommitteeMember} name="awardName" htmlFor="text-awardName-id" label="Award Name">
                            <TextInput id="text-awardName-id" name="awardName" />
                        </FormField>
                        <FormField required={value.isCommitteeMember} name="awardYear" htmlFor="text-awardYear-id" label="Year Awarded">
                            <TextInput id="text-awardYear-id" name="awardYear" />
                        </FormField>
                    </>
                )}

                <FormField name="notes" htmlFor="text-notes-id" label="Member Notes">
                    <TextArea id="text-notes-id" name="notes" />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Submit" />
                    <Button type="reset" label="Reset" />
                </Box>
            </Form>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Member</Text>
                    <Text>Member ID: {createdID}</Text>
                </Box>
            )}
            {isSuccessful && (
                <Popup text="Successfully Added Member" success={true} />
            )}
            {isError && (
                <Popup text="An error occurred" success={false} />
            )}
        </Box>
    )
}

export default CreateMember;