import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Header, Image, Button } from 'grommet';
import { Menu as MenuIcon } from "grommet-icons"
import Logo from "../assets/mobileLogo.png";
import ArchiveSidebar from "./ArchiveSidebar";

const ArchiveBody = () => {

    const [isOpenSidebar, setIsOpenSidebar] = useState(false);

    const toggleSidebar = () => {
        setIsOpenSidebar(!isOpenSidebar);
    }

    return isOpenSidebar ?
        <ArchiveSidebar mobile={true} toggleOpen={toggleSidebar} /> :
        (
            <Header pad="medium" background={{ "color": "brand" }}>
                <NavLink to="/" >
                    <Image src={Logo} />
                </NavLink>
                <Button onClick={toggleSidebar}>
                    <MenuIcon />
                </Button>
            </Header>
        )
}

export default ArchiveBody;