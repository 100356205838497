export const listTeamsWithDivisions = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        sport
      }
      nextToken
    }
  }
`;

export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      displayName
      createdAt
      updatedAt
      instances {
        items{
          id
          compDates
          year
        }
      }
    }
  }
`;

export const getCompInstance = /* GraphQL */ `
  query GetCompInstance($id: ID!) {
    getCompInstance(id: $id) {
      id
      compDates
      year
      createdAt
      updatedAt
      media {
        items {
          id
        }
      }
      teams {
        items {
          id
          division {
            displayName
          }
          team {
            displayName
          }
        }
      }
      competition {
        id
        displayName
        createdAt
        updatedAt
      }
      location {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      displayName
      sport
      compTeams {
        items {
          id
          competition {
            year
            competition {
              displayName
            }
          }
        }
      }
    }
  }
`;

export const getCompTeam = /* GraphQL */ `
  query GetCompTeam($id: ID!) {
    getCompTeam(id: $id) {
      id
      resultID
      grandChamp
      media {
        items {
          id
          type
          originalName
        }
        nextToken
      }
      members {
        items {
          member {
            id
            displayName
          }
        }
      }
      coaches {
        items {
          coach {
            id
            displayName
          }
        }
      }
      team {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      competition {
        id
        competition {
          displayName
        }
        compDates
        year
        createdAt
        updatedAt
      }
      result {
        id
        displayName
        createdAt
        updatedAt
      }
      division {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const getMoreCompTeamMedia = /* GraphQL */ `
  query GetCompTeam(
      $id: ID!
      $nextToken: String
    ) {
    getCompTeam(id: $id) {
      media(nextToken: $nextToken) {
        items {
          id
          type
          originalName
        }
        nextToken
      }
    }
  }
`;

export const getCompTeamData = /* GraphQL */ `
  query getCompTeamData(    
      $limit: Int
      $nextToken: String
    ) {
    listCompInstances(limit: $limit, nextToken: $nextToken) {
      items {
        id
        competition {
          displayName
        }
        year
      }
    }
    listDivisions(limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
      }
    }
    listCompResults(limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
      }
    }
  }
`;

export const listCompInstances = /* GraphQL */ `
  query ListCompInstances(
    $filter: ModelCompInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        competition {
          displayName
        }
      }
      nextToken
    }
  }
`;

export const getTeamsForInstance = /* GraphQL */ `
  query getTeamsForInstance($id: ID!) {
    getCompInstance(id: $id) {
      teams {
        items {
          id
          team {
            displayName
          }
        }
      }
    }
  }
`;

export const searchMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
      }
      nextToken
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        isAthlete
        isCoach
        profilePhoto
        lifeMemberStatus {
          isLifeMember
        }
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      displayName
      lifeMemberStatus {
        isLifeMember
        yearInducted
      }
      awards {
        displayName
        year
      }
      isAthlete
      isCoach
      committeePositions {
        displayName
        year
        executivePosition
      }
      notes
      profilePhoto
      media {
        items {
          media {
            id
            originalName
            type
          }
        }
        nextToken
      }
      teams {
        items {
          team {
            id
            team {
              displayName
              sport
            }
            competition {
              year
              competition {
                displayName
              }
            }
          }
        }
        nextToken
      }
      teamsCoached {
        items {
          team {
            id
            team {
              displayName
              sport
            }
            competition {
              year
              competition {
                displayName
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getMoreMemberMedia = /* GraphQL */ `
  query GetMemberMedia(
      $id: ID!
      $nextToken: String
    ) {
      getMember(id: $id) {
      media(nextToken: $nextToken) {
        items {
          media {
            id
            type
            originalName
          }
        }
        nextToken
      }
    }
  }
`;