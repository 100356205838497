import { Box, Form, FormField, TextInput, Button, Text } from 'grommet'
import React, { useState } from 'react'
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { createCompetition } from '../../graphql/mutations';

const CreateCompetition = () => {
    const [value, setValue] = useState({ name: "" });
    const [createdID, setCreatedID] = useState("");

    const submitCompetition = async (displayName) => {
        try {
            const { data } = await API.graphql({
                query: createCompetition,
                variables: { input: { displayName } },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            setCreatedID(data.createCompetition.id);
        } catch (error) {
            console.log(`Error executing mutation: ${error}`);
            console.log(`Error executing mutation: ${JSON.stringify(error)}`);
        }
    }

    return (
        <Box pad="large" >
            <NavLink to="/competitions">
                <Button primary label="Back To Competitions" />
            </NavLink>
            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({ name: "" })}
                onSubmit={() => submitCompetition(value.name)}
            >
                <FormField name="name" htmlFor="text-input-id" label="New Competition Name">
                    <TextInput id="text-input-id" name="name" />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Submit" />
                    <Button type="reset" label="Reset" />
                </Box>
            </Form>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Competition</Text>
                    <Text>Competition ID: {createdID}</Text>
                </Box>
            )}
        </Box>
    )
}

export default CreateCompetition;