import React, { useState, useEffect } from "react";
import { Grid, Image, Box, Layer, Button, Video, Text, Accordion, AccordionPanel } from "grommet";
import { Close } from "grommet-icons"
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";

const MediaGallery = ({ mediaUrls }) => {
    const [focusImage, setFocusImage] = useState("");
    const videos = mediaUrls.filter(({ type }) => type === "video");
    const images = mediaUrls.filter(({ type }) => type === "photo");

    const [user, setUser] = useState(false);

    useEffect(async () => {
        const isAuthenticated = async () => {
            try {
                const userResponse = await Auth.currentAuthenticatedUser();
                setUser(userResponse);
            }
            catch {
                setUser(false);
            }
        };
        await isAuthenticated();
    }, []);

    const closeModal = () => {
        setFocusImage("");
    }

    return (
        <Box gap="medium" style={{ maxWidth: "72vw" }} alignSelf="center">
            {focusImage && <Layer
                position="center"
                onClickOutside={closeModal}
                onEsc={closeModal}
            >
                <Box gap="small" pad="medium" align="center" justify="center">
                    <Button alignSelf="end" onClick={closeModal}>
                        <Close />
                    </Button>
                    <Image fill fit="contain" key={focusImage.id} src={focusImage.url} />
                    {user &&
                        <NavLink to={`/admin/addPhotoMembers/${focusImage.id}`}>
                            <Button primary label="Add Members To Photo" />
                        </NavLink>
                    }
                </Box>
            </Layer>}
            {videos.length > 0 &&
                <Accordion>
                    <AccordionPanel label="Competition Video">
                        <Box>
                            <Text>Video will take a long time to load, maybe a few minutes</Text>
                            <Text>It will say "NaN:NaN" in bottom right until loaded</Text>
                            <Text>Please be patient 😊</Text>
                            {videos.map(({ url }) => {
                                return <Video key={url} src={url} />
                            })}
                        </Box>
                    </AccordionPanel>
                </Accordion>
            }
            <Grid gap="medium" columns="small">
                {images.map(({ url, id }) => {
                    return <Box flex>
                        <Image onClick={() => setFocusImage({ url, id })} fill fit="contain" key={url} src={url} />
                    </Box>
                })}
            </Grid>
        </Box>
    )
}

export default MediaGallery;