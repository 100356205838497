import React, { useEffect, useState } from 'react';
import { Auth, API } from "aws-amplify";
import { Box, Button, Grid } from 'grommet';
import { useParams, NavLink } from "react-router-dom";
import { getCompetition } from "../graphql/custom-queries";
import InstanceCard from '../components/InstanceCard';
import MoncadSpinner from '../components/MoncadSpinner';

const SingleComp = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [displayName, setDisplayName] = useState("");
    const [instances, setInstances] = useState([]);
    const { id } = useParams();

    const [user, setUser] = useState(false);

    useEffect(async () => {
        const isAuthenticated = async () => {
            try {
                const userResponse = await Auth.currentAuthenticatedUser();
                setUser(userResponse);
            }
            catch {
                setUser(false);
            }
        };
        await isAuthenticated();
    }, []);

    const getCompData = async () => {
        try {
            const { data } = await API.graphql({
                query: getCompetition,
                variables: {
                    id
                },
                authMode: "AWS_IAM"
            });
            setDisplayName(data.getCompetition.displayName);
            setInstances(data.getCompetition.instances.items);
            setIsLoading(false);
        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(getCompData, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <>
                    <Box gap="small">
                        {user && <NavLink to={`/admin/createCompInstance/${id}`}>
                            <Button primary label="Create New Instance" />
                        </NavLink>}
                        <Grid gap="medium" columns="small" pad="medium">
                            {instances.map(comp => {
                                return <InstanceCard key={comp.id} props={{ displayName, ...comp }} />
                            })}
                        </Grid>
                    </Box>
                </>
            }
        </Box >
    )
}

export default SingleComp;