/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      type
      originalName
      createdAt
      updatedAt
      members {
        nextToken
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
      comp {
        id
        compDates
        year
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMediaByName = /* GraphQL */ `
  query GetMediaByName(
    $type: MediaType
    $originalName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMediaByName(
      type: $type
      originalName: $originalName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberMedia = /* GraphQL */ `
  query GetMemberMedia($id: ID!) {
    getMemberMedia(id: $id) {
      id
      mediaID
      memberID
      createdAt
      updatedAt
      media {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMemberMedias = /* GraphQL */ `
  query ListMemberMedias(
    $filter: ModelMemberMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mediaID
        memberID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      displayName
      lifeMemberStatus {
        isLifeMember
        yearInducted
      }
      awards {
        displayName
        year
      }
      isAthlete
      isCoach
      committeePositions {
        displayName
        year
        executivePosition
      }
      notes
      profilePhoto
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      teamsCoached {
        nextToken
      }
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      teamID
      memberID
      createdAt
      updatedAt
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        memberID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamCoach = /* GraphQL */ `
  query GetTeamCoach($id: ID!) {
    getTeamCoach(id: $id) {
      id
      teamID
      coachID
      createdAt
      updatedAt
      coach {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTeamCoaches = /* GraphQL */ `
  query ListTeamCoaches(
    $filter: ModelTeamCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamCoaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        coachID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      displayName
      sport
      type
      createdAt
      updatedAt
      compTeams {
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompTeam = /* GraphQL */ `
  query GetCompTeam($id: ID!) {
    getCompTeam(id: $id) {
      id
      resultID
      grandChamp
      createdAt
      updatedAt
      media {
        nextToken
      }
      members {
        nextToken
      }
      coaches {
        nextToken
      }
      team {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      competition {
        id
        compDates
        year
        createdAt
        updatedAt
      }
      result {
        id
        displayName
        createdAt
        updatedAt
      }
      division {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompTeams = /* GraphQL */ `
  query ListCompTeams(
    $filter: ModelCompTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompInstance = /* GraphQL */ `
  query GetCompInstance($id: ID!) {
    getCompInstance(id: $id) {
      id
      compDates
      year
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      competition {
        id
        displayName
        createdAt
        updatedAt
      }
      location {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompInstances = /* GraphQL */ `
  query ListCompInstances(
    $filter: ModelCompInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        compDates
        year
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      displayName
      createdAt
      updatedAt
      instances {
        nextToken
      }
    }
  }
`;
export const listCompetitions = /* GraphQL */ `
  query ListCompetitions(
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompResult = /* GraphQL */ `
  query GetCompResult($id: ID!) {
    getCompResult(id: $id) {
      id
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const listCompResults = /* GraphQL */ `
  query ListCompResults(
    $filter: ModelCompResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      displayName
      createdAt
      updatedAt
      comps {
        nextToken
      }
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      displayName
      createdAt
      updatedAt
      teams {
        nextToken
      }
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
