import { Box, Button, Text } from "grommet";
import React from "react";
import { useParams } from "react-router-dom";
import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { Download } from "grommet-icons";

const SingleMedia = () => {
    const { id } = useParams();

    return (
        <Box height="100vh" background={{ "color": "background-back" }} justify="center" align="center">
            <Box width="100%" align="end" margin="medium">
                <Button pad="large" icon={<Download />} primary margin="medium" label="Download" color="brand" onClick={() => { console.log('download') }} />
            </Box>
            <AmplifyS3Image imgKey={id} />
            <Box>
                <Box direction="row" gap="medium">
                    <Text>Competition</Text>
                    <Text color="grey">Comp competed</Text>
                </Box>
                <Box direction="row" gap="medium">
                    <Text>Team</Text>
                    <Text color="grey">Team competed</Text>
                </Box>
                <Box direction="row" gap="medium">
                    <Text>Athletes</Text>
                    <Text color="grey">Athletes in photo</Text>
                </Box>
            </Box>
        </Box>
    )
}

export default SingleMedia;