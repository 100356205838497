import { Box, Carousel, Image } from 'grommet'
import React from 'react'
import IMG_4279 from "../assets/covers/IMG_4279.JPG";
import IMG_0430 from "../assets/covers/IMG_0430.JPG";

import IMG_9211 from "../assets/covers/IMG_9211.JPG";
import IMG_8357 from "../assets/covers/IMG_8357.JPG";
import IMG_7077 from "../assets/covers/IMG_7077.JPG";
// import IMG_8514 from "../assets/covers/IMG_8514.JPG";


const HomePage = () => {
    const images = [IMG_4279, IMG_8357, IMG_9211, IMG_7077, IMG_0430];

    return (
        <Box height="100vh">
            <Carousel controls={false} fill play={3000}>
                {images.map(image => <Image key={image} fit="cover" fill src={image} />)}
            </Carousel>
        </Box>
    )
}

export default HomePage;