import CompCard from "../components/CompCard";
import { Box, Grid } from 'grommet';
import React, { useEffect, useState } from 'react'
import { API } from "aws-amplify";
import { listCompetitions } from "../graphql/queries";
import MoncadSpinner from "../components/MoncadSpinner";

const CompList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allComps, setAllComps] = useState([]);

    const getComps = async () => {
        try {
            const { data } = await API.graphql({
                query: listCompetitions,
                authMode: "AWS_IAM"
            });
            setAllComps(data.listCompetitions.items);
            setIsLoading(false);
        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        getComps();
    }, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {
                isLoading ?
                    <MoncadSpinner /> :
                    <Grid gap="medium" columns="small" pad="medium">
                        {allComps.map(comp => {
                            return <CompCard key={comp.id} props={comp} />
                        })}
                    </Grid>
            }
        </Box>
    )
}

export default CompList;