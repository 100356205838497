import { Box, Heading, Button, Text, List, Layer, Spinner, Image } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useState, useEffect } from 'react'
import { API, Storage } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import MemberSearch from '../../components/MemberSearch';
import { createMemberMedia } from '../../graphql/mutations';
import Popup from "../../components/Popup";

const AddPhotoMembers = () => {
    const history = useHistory();
    const { photoID } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);
    const [members, setMembers] = useState([]);
    const [image, setImage] = useState("");

    useEffect(() => {
        const getImage = async () => {
            try {
                const url = await Storage.get(photoID);
                setImage(url);
            }
            catch (error) {
                console.error(error);
            }
        };
        getImage();
    }, []);

    const onSuccess = () => {
        setIsUploading(false);
        setIsSuccessful(true);
    }

    const onFail = () => {
        setIsUploading(false);
        setIsError(true);
    }

    const submitMembers = async () => {
        setIsUploading(true);

        for (const member of members) {
            const variables = {
                input: {
                    mediaID: photoID,
                    memberID: member.id
                }
            }
            try {
                await API.graphql({
                    query: createMemberMedia,
                    variables,
                    authMode: "AMAZON_COGNITO_USER_POOLS"
                });
            } catch (error) {
                onFail();
                console.log(`Error executing mutation: ${error}`);
                console.log(`Error executing mutation: ${JSON.stringify(error)}`);
                return;
            }
        }

        onSuccess();
    }


    const updateMembers = (member) => {
        setMembers([...members, member]);
    }

    const removeMember = (item) => {
        const newMembers = members.filter(member => member !== item);
        setMembers(newMembers);
    }

    return (
        <Box height="100vh" overflow="auto" background={{ "color": "background-back" }} pad="medium">
            {isUploading && (
                <Layer>
                    <Box
                        align="center"
                        justify="center"
                        gap="small"
                        direction="row"
                        alignSelf="center"
                        pad="large"
                    >
                        <Spinner />
                        <Text>Uploading...</Text>
                    </Box>
                </Layer>
            )}
            {isSuccessful && (
                <Popup text="Successfully added" success={true} />
            )}
            {isError && (
                <Popup text="An error occurred" success={false} />
            )}

            <Button primary label="Go Back" onClick={() => history.goBack()} />
            <Image flex src={image} fill fit="contain" />
            <Heading level="3">Members</Heading>
            <MemberSearch updateMembers={updateMembers} />
            <Box flex overflow="auto">
                <List
                    data={members}
                    pad={"small"}
                    action={(item) =>
                        <Button key={`member-${item.id}`} icon={<Close />} onClick={() => removeMember(item)} />
                    }
                />
            </Box>
            <Button primary label="Submit" onClick={submitMembers} />

        </Box>
    )
}

export default AddPhotoMembers;