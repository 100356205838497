/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://sypvjrnx75csdoizha2bo5ccuy.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456",
    "aws_cognito_identity_pool_id": "ap-southeast-2:2c793f9c-5fa2-471e-90fe-df8d7062ff16",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_koQOZKndP",
    "aws_user_pools_web_client_id": "7a8akla3815f6kio6447bparqu",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "moncadarchivesstoragebucket84032-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ]
};


export default awsmobile;
