/**
 * Get the minimum and maximum years from an array
 * @param {*} years Array of years to find range for
 * @returns yearMin - minimum year in array, yearMax - maximum year in array
 */
const getYearRange = (years) => {
    if (!years) return ["", ""];
    const sortedYears = years.sort(function (a, b) {
        return a - b;
    });
    const yearMin = sortedYears[0];
    const yearMax = sortedYears[sortedYears.length - 1];
    return [yearMin, yearMax];
}

const sortMediaByName = (itemA, itemB) => {
    if (itemA.originalName < itemB.originalName) { return -1; }
    if (itemA.originalName > itemB.originalName) { return 1; }
    return 0;
}

const sortMembersAlphabetically = (itemA, itemB) => {
    if (itemA.displayName < itemB.displayName) { return -1; }
    if (itemA.displayName > itemB.displayName) { return 1; }
    return 0;
}

module.exports = {
    getYearRange,
    sortMediaByName,
    sortMembersAlphabetically
}