import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify"
import { Text } from "grommet";
import { Route, useHistory } from "react-router-dom";

const ProtectedRoute = ({ children, path, exact, key }) => {
    const history = useHistory();
    const [userState, setUserState] = useState(false);

    useEffect(async () => {
        const isAuthenticated = async () => {
            try {
                const userResponse = await Auth.currentAuthenticatedUser();
                setUserState(userResponse);
            }
            catch {
                history.push(`/admin`);
            }
        };
        await isAuthenticated();
    }, []);

    return userState ?
        <Route
            key={key}
            path={path}
            exact={exact}
            children={children}
        />
        : <Text>Not logged in</Text>
}

export default ProtectedRoute;