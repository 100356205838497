import React from "react";
import { Main } from "grommet";
import { Switch, Route } from "react-router-dom";
import routes from "../routes";
import ProtectedRoute from "../components/ProtectedRoute";

const ArchiveBody = () => {
    return (
        <Main height="100vh" overflow="auto">
            <Switch>
                {routes.map((route, index) => (
                    route.isProtected ?
                        <ProtectedRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                        /> :
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                        />
                ))}
            </Switch>
        </Main>
    );
}

export default ArchiveBody;