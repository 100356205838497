import SingleMember from "./components/SingleMember";
import HomePage from "./pages/HomePage";
import SingleMedia from "./components/SingleMedia";
import SingleComp from "./pages/SingleComp";
import SingleInstance from "./pages/SingleInstance";
import SingleTeam from "./pages/SingleTeam";
import AdminPage from "./pages/AdminPage";
import CoachList from "./pages/CoachList";
import AthleteList from "./pages/AthleteList";
import TeamList from "./pages/TeamList";
import CompList from "./pages/CompList";
import CreateDivision from "./pages/admin/CreateDivision";
import CreateCompetition from "./pages/admin/CreateCompetition";
import CreateTeam from "./pages/admin/CreateTeam";
import CreateLocation from "./pages/admin/CreateLocation";
import CreateCompInstance from "./pages/admin/CreateCompInstance";
import CreateCompTeam from "./pages/admin/CreateCompTeam";
import UploadImages from "./pages/admin/uploadImages";
import CreateBulkMembers from "./pages/admin/CreateBulkMembers";
import CreateMember from "./pages/admin/CreateMember";
import CreateResult from "./pages/admin/CreateResult";
import SingleCompTeam from "./pages/SingleCompTeam";
import AddTeamMembers from "./pages/admin/AddTeamMembers";
import AddPhotoMembers from "./pages/admin/AddPhotoMembers";

const routes = [
    {
        path: "/",
        exact: true,
        sidebar: () => <div>home!</div>,
        main: () => <HomePage />
    },
    {
        path: "/athletes",
        exact: true,
        sidebar: () => <div>athletes</div>,
        main: () => <AthleteList isCoach={false} />
    },
    {
        path: "/member/:id",
        sidebar: () => <div>athletes</div>,
        main: () => <SingleMember />
    },
    {
        path: "/coaches",
        sidebar: () => <div>shoelaces!</div>,
        main: () => <CoachList />
    },
    {
        path: "/teams",
        exact: true,
        sidebar: () => <div>shoelaces!</div>,
        main: () => <TeamList />
    },
    {
        path: "/teams/:id",
        main: () => <SingleTeam />
    },
    {
        path: "/competitionTeam/:id",
        main: () => <SingleCompTeam />
    },
    {
        path: "/competitions",
        exact: true,
        sidebar: () => <div>shoelaces!</div>,
        main: () => <CompList />
    },
    {
        path: "/competitions/:id",
        main: () => <SingleComp />
    },
    {
        path: "/events/:id",
        main: () => <SingleInstance />
    },
    {
        path: "/media/:id",
        sidebar: () => <div>media</div>,
        main: () => <SingleMedia />
    },
    {
        path: "/life-members",
        exact: true,
        sidebar: () => <div>athletes</div>,
        main: () => <AthleteList isLifeMember={true} />
    },
    {
        path: "/admin",
        exact: true,
        main: () => <AdminPage />
    },
    {
        path: "/admin/createDivision",
        main: () => <CreateDivision />,
        isProtected: true
    },
    {
        path: "/admin/createLocation",
        main: () => <CreateLocation />,
        isProtected: true
    },
    {
        path: "/admin/createCompetition",
        main: () => <CreateCompetition />,
        isProtected: true
    },
    {
        path: "/admin/createCompInstance/:compID",
        main: () => <CreateCompInstance />,
        isProtected: true
    },
    {
        path: "/admin/createTeam",
        main: () => <CreateTeam />,
        isProtected: true
    },
    {
        path: "/admin/createCompTeam/:teamID",
        main: () => <CreateCompTeam />,
        isProtected: true
    },
    {
        path: "/admin/addTeamMembers/:compTeamID",
        main: () => <AddTeamMembers />,
        isProtected: true
    },
    {
        path: "/admin/addPhotoMembers/:photoID",
        main: () => <AddPhotoMembers />,
        isProtected: true
    },
    {
        path: "/admin/uploadImages",
        main: () => <UploadImages />,
        isProtected: true
    },
    {
        path: "/admin/createBulkMembers",
        main: () => <CreateBulkMembers />,
        isProtected: true
    },
    {
        path: "/admin/createMember",
        main: () => <CreateMember />,
        isProtected: true
    },
    {
        path: "/admin/createResult",
        main: () => <CreateResult />,
        isProtected: true
    },
];

export default routes;