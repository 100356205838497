import React, { useEffect, useState } from 'react'
import { Box, Grid, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import { API } from "aws-amplify";
import { listMembers } from "../../graphql/custom-queries";
import { sortMembersAlphabetically } from "../../utils/utils";
import MemberCard from '../MemberCard';
import MoncadSpinner from '../MoncadSpinner';

const MemberList = ({ isCoach, isAthlete, isLifeMember }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [allMembers, setAllMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [value, setValue] = useState('');

    const getMembers = async () => {
        let variables = {};

        if (isCoach) variables = {
            filter: {
                isCoach: { eq: true }
            }
        }
        else if (isAthlete) variables = {
            filter: {
                isAthlete: { eq: true }
            }
        }

        try {
            const { data } = await API.graphql({
                query: listMembers,
                variables,
                authMode: "AWS_IAM"
            });

            let memberData = data.listMembers.items;
            let nextToken = data.listMembers.nextToken;

            while (nextToken) {
                if (isCoach) variables = {
                    filter: {
                        isCoach: { eq: true }
                    },
                    nextToken
                }
                else if (isAthlete) variables = {
                    filter: {
                        isAthlete: { eq: true }
                    },
                    nextToken
                }
                const { data: nextMembers } = await API.graphql({
                    query: listMembers,
                    variables,
                    authMode: "AWS_IAM"
                });
                nextToken = nextMembers.listMembers.nextToken;
                memberData.push(...nextMembers.listMembers.items);
            }

            if (isLifeMember) {
                memberData = memberData.filter(member => member.lifeMemberStatus.isLifeMember)
            }

            memberData.sort(sortMembersAlphabetically);

            setAllMembers(memberData);
            setFilteredMembers(memberData);
            setIsLoading(false);
        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        getMembers();
    }, []);

    const onChange = (event) => {
        const { value: newValue } = event.target;
        setValue(newValue);

        const filtered = allMembers.filter(({ displayName }) => displayName.toLowerCase().indexOf(newValue.toLowerCase()) >= 0);
        setFilteredMembers(filtered);
    }

    return (
        <Box height="100vh" background={{ "color": "background-back" }} pad="medium" gap="small">
            {isLoading ?
                <MoncadSpinner />
                :
                <>
                    <TextInput
                        icon={<Search />}
                        placeholder={isCoach ? "Search for a coach" : "Search for an athlete"}
                        value={value}
                        onChange={onChange}
                    />
                    <Box overflow="auto" margin={{ "top": "medium" }}>
                        <Grid gap="medium" columns="small" pad="medium">
                            {filteredMembers.map(member => <MemberCard key={member.id} props={member} />)}
                        </Grid>
                    </Box>
                </>}
        </Box>
    )
}

export default MemberList;