import React from 'react';
import { Box, Spinner } from 'grommet';

const MoncadSpinner = () => {
    return (
        <Box align="center" justify="center" height="100%" width="100%">
            <Spinner size="large" />
        </Box>
    );
};

export default MoncadSpinner;
