import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Sidebar, Button, Image } from 'grommet';
import { Group, Yoga, Trophy, Login, Home, Star, Blank, Close } from "grommet-icons";
import CoachIcon from "../icons/CoachIcon";
import Logo from "../assets/moncadLogo.png";

const ArchiveSidebar = ({ mobile = false, toggleOpen }) => {
    return (
        <Sidebar pad="none" height="100vh" width={mobile ? "100vw" : ""} style={{ position: "sticky", top: 0, zIndex: 1000 }}>
            <Box align="start" justify="center" fill="vertical" background={{ "color": "brand" }} pad={mobile ? "large" : "medium"} gap="large">
                {mobile && <Button alignSelf="end" onClick={toggleOpen}>
                    <Close />
                </Button>}
                <Box alignSelf="center" align="center">
                    <NavLink to="/" >
                        <Image src={Logo} onClick={toggleOpen} />
                    </NavLink>
                </Box>
                <Box align="start" justify="start" gap="medium" flex>
                    <NavLink to="/" style={{ color: 'inherit' }}>
                        {/* TODO: Use rounded button style similar to cards to highlight white the route you're on */}
                        <Button onClick={toggleOpen} label="Home" icon={<Home />} plain margin={{ "bottom": "small" }} />
                    </NavLink>
                    <NavLink to="/athletes" style={{ color: 'inherit' }} onClick={toggleOpen}>
                        <Button onClick={toggleOpen} label="Athletes" icon={<Yoga />} plain margin={{ "bottom": "small" }} />
                    </NavLink>
                    <NavLink to="/coaches" style={{ color: 'inherit' }}>
                        <Button onClick={toggleOpen} label="Coaches" icon={<Blank><CoachIcon /></Blank>} plain margin={{ "bottom": "small" }} />
                    </NavLink>
                    <NavLink to="/teams" style={{ color: 'inherit' }}>
                        <Button onClick={toggleOpen} label="Teams" icon={<Group />} plain margin={{ "bottom": "small" }} />
                    </NavLink>
                    <NavLink to="/competitions" style={{ color: 'inherit' }}>
                        <Button onClick={toggleOpen} label="Competitions" icon={<Trophy />} plain margin={{ "bottom": "small" }} />
                    </NavLink>
                </Box>
                <Box align="start" justify="end" gap="medium" pad={{ "vertical": "small" }}>
                    <NavLink to="/life-members" style={{ color: "inherit" }}>
                        <Button onClick={toggleOpen} label="Life Members" icon={<Star />} plain />
                    </NavLink>
                    <NavLink to="/admin" style={{ color: "inherit" }}>
                        <Button onClick={toggleOpen} label="Admin" icon={<Login />} plain />
                    </NavLink>
                </Box>
            </Box>
        </Sidebar >
    )
}

export default ArchiveSidebar;