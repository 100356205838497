import React, { useEffect, useState } from 'react'
import { Card, Avatar, CardHeader, Heading, CardBody, Text, Paragraph } from 'grommet';
import { useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import { getYearRange } from '../../utils/utils';
import placeholder from "../../assets/placeholder.png";

const MemberCard = (athleteDetails) => {
    const history = useHistory();

    const navigateToMember = (id) => {
        history.push(`/member/${id}`);
    }

    const { id, displayName, profilePhoto, years, sport } = athleteDetails.props;
    const [yearMin, yearMax] = getYearRange(years);
    const [profileUrl, setProfileUrl] = useState("");

    useEffect(() => {
        const getProfileUrl = async () => {
            const retrieveUrl = await Storage.get(profilePhoto);
            setProfileUrl(retrieveUrl);
        }
        if (profilePhoto) getProfileUrl();
    }, []);

    return (
        <Card onClick={() => navigateToMember(id)} justify="center" align="center" width="medium" pad="medium" background={{ "color": "background" }}>
            <Avatar src={profileUrl ? profileUrl : placeholder} background={{ "color": "light-2" }} size="2xl" />
            <CardHeader align="center" direction="row" flex={false} justify="between" gap="none" pad="small">
                <Heading level="2" size="small" margin="none" textAlign="center">
                    {displayName}
                </Heading>
            </CardHeader>
            <CardBody>
                <Text textAlign="center">
                    {sport ? (sport.length > 1 ? "Cheer & Dance" : sport) : ""}
                </Text>
                <Paragraph size="small" margin="none" textAlign="center">
                    {yearMin ? `${yearMin} - ${yearMax}` : ""}
                </Paragraph>
            </CardBody>
        </Card>
    )
};

export default MemberCard;