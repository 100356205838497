/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      type
      originalName
      createdAt
      updatedAt
      members {
        nextToken
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
      comp {
        id
        compDates
        year
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      type
      originalName
      createdAt
      updatedAt
      members {
        nextToken
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
      comp {
        id
        compDates
        year
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      type
      originalName
      createdAt
      updatedAt
      members {
        nextToken
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
      comp {
        id
        compDates
        year
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMemberMedia = /* GraphQL */ `
  mutation CreateMemberMedia(
    $input: CreateMemberMediaInput!
    $condition: ModelMemberMediaConditionInput
  ) {
    createMemberMedia(input: $input, condition: $condition) {
      id
      mediaID
      memberID
      createdAt
      updatedAt
      media {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMemberMedia = /* GraphQL */ `
  mutation UpdateMemberMedia(
    $input: UpdateMemberMediaInput!
    $condition: ModelMemberMediaConditionInput
  ) {
    updateMemberMedia(input: $input, condition: $condition) {
      id
      mediaID
      memberID
      createdAt
      updatedAt
      media {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMemberMedia = /* GraphQL */ `
  mutation DeleteMemberMedia(
    $input: DeleteMemberMediaInput!
    $condition: ModelMemberMediaConditionInput
  ) {
    deleteMemberMedia(input: $input, condition: $condition) {
      id
      mediaID
      memberID
      createdAt
      updatedAt
      media {
        id
        type
        originalName
        createdAt
        updatedAt
      }
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      displayName
      lifeMemberStatus {
        isLifeMember
        yearInducted
      }
      awards {
        displayName
        year
      }
      isAthlete
      isCoach
      committeePositions {
        displayName
        year
        executivePosition
      }
      notes
      profilePhoto
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      teamsCoached {
        nextToken
      }
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      displayName
      lifeMemberStatus {
        isLifeMember
        yearInducted
      }
      awards {
        displayName
        year
      }
      isAthlete
      isCoach
      committeePositions {
        displayName
        year
        executivePosition
      }
      notes
      profilePhoto
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      teamsCoached {
        nextToken
      }
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      displayName
      lifeMemberStatus {
        isLifeMember
        yearInducted
      }
      awards {
        displayName
        year
      }
      isAthlete
      isCoach
      committeePositions {
        displayName
        year
        executivePosition
      }
      notes
      profilePhoto
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      teamsCoached {
        nextToken
      }
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      teamID
      memberID
      createdAt
      updatedAt
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      teamID
      memberID
      createdAt
      updatedAt
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      teamID
      memberID
      createdAt
      updatedAt
      member {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTeamCoach = /* GraphQL */ `
  mutation CreateTeamCoach(
    $input: CreateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    createTeamCoach(input: $input, condition: $condition) {
      id
      teamID
      coachID
      createdAt
      updatedAt
      coach {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeamCoach = /* GraphQL */ `
  mutation UpdateTeamCoach(
    $input: UpdateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    updateTeamCoach(input: $input, condition: $condition) {
      id
      teamID
      coachID
      createdAt
      updatedAt
      coach {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTeamCoach = /* GraphQL */ `
  mutation DeleteTeamCoach(
    $input: DeleteTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    deleteTeamCoach(input: $input, condition: $condition) {
      id
      teamID
      coachID
      createdAt
      updatedAt
      coach {
        id
        displayName
        isAthlete
        isCoach
        notes
        profilePhoto
        createdAt
        updatedAt
      }
      team {
        id
        resultID
        grandChamp
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      displayName
      sport
      type
      createdAt
      updatedAt
      compTeams {
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      displayName
      sport
      type
      createdAt
      updatedAt
      compTeams {
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      displayName
      sport
      type
      createdAt
      updatedAt
      compTeams {
        nextToken
      }
    }
  }
`;
export const createCompTeam = /* GraphQL */ `
  mutation CreateCompTeam(
    $input: CreateCompTeamInput!
    $condition: ModelCompTeamConditionInput
  ) {
    createCompTeam(input: $input, condition: $condition) {
      id
      resultID
      grandChamp
      createdAt
      updatedAt
      media {
        nextToken
      }
      members {
        nextToken
      }
      coaches {
        nextToken
      }
      team {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      competition {
        id
        compDates
        year
        createdAt
        updatedAt
      }
      result {
        id
        displayName
        createdAt
        updatedAt
      }
      division {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompTeam = /* GraphQL */ `
  mutation UpdateCompTeam(
    $input: UpdateCompTeamInput!
    $condition: ModelCompTeamConditionInput
  ) {
    updateCompTeam(input: $input, condition: $condition) {
      id
      resultID
      grandChamp
      createdAt
      updatedAt
      media {
        nextToken
      }
      members {
        nextToken
      }
      coaches {
        nextToken
      }
      team {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      competition {
        id
        compDates
        year
        createdAt
        updatedAt
      }
      result {
        id
        displayName
        createdAt
        updatedAt
      }
      division {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompTeam = /* GraphQL */ `
  mutation DeleteCompTeam(
    $input: DeleteCompTeamInput!
    $condition: ModelCompTeamConditionInput
  ) {
    deleteCompTeam(input: $input, condition: $condition) {
      id
      resultID
      grandChamp
      createdAt
      updatedAt
      media {
        nextToken
      }
      members {
        nextToken
      }
      coaches {
        nextToken
      }
      team {
        id
        displayName
        sport
        type
        createdAt
        updatedAt
      }
      competition {
        id
        compDates
        year
        createdAt
        updatedAt
      }
      result {
        id
        displayName
        createdAt
        updatedAt
      }
      division {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCompInstance = /* GraphQL */ `
  mutation CreateCompInstance(
    $input: CreateCompInstanceInput!
    $condition: ModelCompInstanceConditionInput
  ) {
    createCompInstance(input: $input, condition: $condition) {
      id
      compDates
      year
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      competition {
        id
        displayName
        createdAt
        updatedAt
      }
      location {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompInstance = /* GraphQL */ `
  mutation UpdateCompInstance(
    $input: UpdateCompInstanceInput!
    $condition: ModelCompInstanceConditionInput
  ) {
    updateCompInstance(input: $input, condition: $condition) {
      id
      compDates
      year
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      competition {
        id
        displayName
        createdAt
        updatedAt
      }
      location {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompInstance = /* GraphQL */ `
  mutation DeleteCompInstance(
    $input: DeleteCompInstanceInput!
    $condition: ModelCompInstanceConditionInput
  ) {
    deleteCompInstance(input: $input, condition: $condition) {
      id
      compDates
      year
      createdAt
      updatedAt
      media {
        nextToken
      }
      teams {
        nextToken
      }
      competition {
        id
        displayName
        createdAt
        updatedAt
      }
      location {
        id
        displayName
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCompetition = /* GraphQL */ `
  mutation CreateCompetition(
    $input: CreateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    createCompetition(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      instances {
        nextToken
      }
    }
  }
`;
export const updateCompetition = /* GraphQL */ `
  mutation UpdateCompetition(
    $input: UpdateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    updateCompetition(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      instances {
        nextToken
      }
    }
  }
`;
export const deleteCompetition = /* GraphQL */ `
  mutation DeleteCompetition(
    $input: DeleteCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    deleteCompetition(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      instances {
        nextToken
      }
    }
  }
`;
export const createCompResult = /* GraphQL */ `
  mutation CreateCompResult(
    $input: CreateCompResultInput!
    $condition: ModelCompResultConditionInput
  ) {
    createCompResult(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const updateCompResult = /* GraphQL */ `
  mutation UpdateCompResult(
    $input: UpdateCompResultInput!
    $condition: ModelCompResultConditionInput
  ) {
    updateCompResult(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompResult = /* GraphQL */ `
  mutation DeleteCompResult(
    $input: DeleteCompResultInput!
    $condition: ModelCompResultConditionInput
  ) {
    deleteCompResult(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      comps {
        nextToken
      }
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      comps {
        nextToken
      }
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      comps {
        nextToken
      }
    }
  }
`;
export const createDivision = /* GraphQL */ `
  mutation CreateDivision(
    $input: CreateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    createDivision(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      teams {
        nextToken
      }
    }
  }
`;
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision(
    $input: UpdateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    updateDivision(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      teams {
        nextToken
      }
    }
  }
`;
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision(
    $input: DeleteDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    deleteDivision(input: $input, condition: $condition) {
      id
      displayName
      createdAt
      updatedAt
      teams {
        nextToken
      }
    }
  }
`;
