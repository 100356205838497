import React, { useEffect, useState } from 'react'
import { API } from "aws-amplify";
import { Box, Grid, Heading, Text } from 'grommet';
import { useParams } from "react-router-dom";
import { getCompInstance } from '../graphql/custom-queries';
import CompTeamCard from '../components/CompTeamCard';
import MoncadSpinner from '../components/MoncadSpinner';

const SingleComp = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [instanceName, setInstanceName] = useState("");
    const [location, setLocation] = useState("");
    const [dates, setDates] = useState("");
    const [compTeams, setCompTeams] = useState([]);
    const { id } = useParams();

    const getInstanceData = async () => {
        try {
            const { data } = await API.graphql({
                query: getCompInstance,
                variables: {
                    id
                },
                authMode: "AWS_IAM"
            });
            setInstanceName(`${data.getCompInstance.competition.displayName} ${data.getCompInstance.year}`);
            setLocation(data.getCompInstance.location.displayName);
            setDates(data.getCompInstance.compDates);
            setCompTeams(data.getCompInstance.teams.items);
            setIsLoading(false);

        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(getInstanceData, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <Box gap="small">
                    <Text weight="bold" size="large">{instanceName}</Text>
                    <Text>{location}</Text>
                    <Text>{dates}</Text>
                    <Heading level="2">Teams</Heading>
                    <Grid gap="medium" columns="small" pad="medium">
                        {compTeams.map(team => {
                            return <CompTeamCard key={team.id} id={team.id} displayName={team.team.displayName} division={team.division.displayName} />
                        })}
                    </Grid>
                </Box>
            }

        </Box >
    )
}

export default SingleComp;