import TeamCard from "../components/TeamCard";
import { Box, Grid, Tabs, Tab } from 'grommet';
import React, { useEffect, useState } from 'react'
import { API } from "aws-amplify";
import { listTeamsWithDivisions } from "../graphql/custom-queries";
import MoncadSpinner from "../components/MoncadSpinner";

const TeamList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allTeams, setAllTeams] = useState([]);

    const getTeams = async () => {
        try {
            const { data } = await API.graphql({
                query: listTeamsWithDivisions,
                authMode: "AWS_IAM"
            });
            setAllTeams(data.listTeams.items);
            setIsLoading(false);
        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        getTeams();
    }, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <Tabs>
                    <Tab title="Cheer" >
                        <Grid gap="medium" columns="small" pad="medium">
                            {allTeams.filter(team => team.sport === "Cheer").map(team => {
                                return <TeamCard key={team.id} props={team} />
                            })}
                        </Grid>
                    </Tab>
                    <Tab title="Dance">
                        <Grid gap="medium" columns="small" pad="medium">
                            {allTeams.filter(team => team.sport === "Dance").map(team => {
                                return <TeamCard key={team.id} props={team} />
                            })}
                        </Grid>
                    </Tab>
                </Tabs>
            }
        </Box>
    )
}

export default TeamList;