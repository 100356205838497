import { Box, Heading, Button, Text, List, Layer, Spinner } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useState } from 'react'
import { API } from "aws-amplify";
import { NavLink, useParams } from "react-router-dom";
import MemberSearch from '../../components/MemberSearch';
import { createTeamCoach, createTeamMember } from '../../graphql/mutations';
import Popup from "../../components/Popup";

const AddTeamMembers = () => {
    const { compTeamID } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);
    const [athletes, setAthletes] = useState([]);
    const [coaches, setCoaches] = useState([]);

    const onSuccess = () => {
        setIsUploading(false);
        setIsSuccessful(true);
    }

    const onFail = () => {
        setIsUploading(false);
        setIsError(true);
    }

    const submitMembers = async () => {
        setIsUploading(true);

        for (const coach of coaches) {
            const variables = {
                input: {
                    teamID: compTeamID,
                    coachID: coach.id
                }
            }
            try {
                await API.graphql({
                    query: createTeamCoach,
                    variables,
                    authMode: "AMAZON_COGNITO_USER_POOLS"
                });
            } catch (error) {
                onFail();
                console.log(`Error executing mutation: ${error}`);
                console.log(`Error executing mutation: ${JSON.stringify(error)}`);
                return;
            }
        }

        for (const athlete of athletes) {
            const variables = {
                input: {
                    teamID: compTeamID,
                    memberID: athlete.id
                }
            }
            try {
                await API.graphql({
                    query: createTeamMember,
                    variables,
                    authMode: "AMAZON_COGNITO_USER_POOLS"
                });
            } catch (error) {
                onFail();
                console.log(`Error executing mutation: ${error}`);
                console.log(`Error executing mutation: ${JSON.stringify(error)}`);
                return;
            }
        }

        onSuccess();
    }

    const updateCoaches = (coach) => {
        setCoaches([...coaches, coach]);
    }

    const updateAthletes = (athlete) => {
        setAthletes([...athletes, athlete]);
    }

    const removeCoach = (item) => {
        const newCoaches = coaches.filter(coach => coach !== item);
        setCoaches(newCoaches);
    }

    const removeAthlete = (item) => {
        const newAthletes = athletes.filter(athlete => athlete !== item);
        setAthletes(newAthletes);
    }

    return (
        <Box pad="large" gap="medium">
            {isUploading && (
                <Layer>
                    <Box
                        align="center"
                        justify="center"
                        gap="small"
                        direction="row"
                        alignSelf="center"
                        pad="large"
                    >
                        <Spinner />
                        <Text>Uploading...</Text>
                    </Box>
                </Layer>
            )}
            {isSuccessful && (
                <Popup text="Successfully added" success={true} />
            )}
            {isError && (
                <Popup text="An error occurred" success={false} />
            )}
            <NavLink to={`/competitionTeam/${compTeamID}`} style={{ color: 'inherit' }}>
                <Button primary label="Back To Comp Team" />
            </NavLink>
            <Box overflow="auto">
                <Heading level="3">Coaches</Heading>
                <MemberSearch isCoach={true} updateMembers={updateCoaches} />
                <Box overflow="auto">
                    <List
                        data={coaches}
                        pad={"small"}
                        action={(item) =>
                            <Button key={`coach-${item.id}`} icon={<Close />} onClick={() => removeCoach(item)} />
                        }
                    />
                </Box>
            </Box>
            <Box>
                <Heading level="3">Athletes</Heading>
                <MemberSearch isAthlete={true} updateMembers={updateAthletes} />
                <Box overflow="auto">
                    <List
                        data={athletes}
                        pad={"small"}
                        action={(item) =>
                            <Button key={`athlete-${item.id}`} icon={<Close />} onClick={() => removeAthlete(item)} />
                        }
                    />
                </Box>
            </Box>
            <Box>
                <Button primary label="Submit" onClick={submitMembers} />
            </Box>
        </Box>
    )
}

export default AddTeamMembers;