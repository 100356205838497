import React, { useEffect, useState } from 'react'
import { API, Auth } from "aws-amplify";
import { Accordion, AccordionPanel, Box, Heading, Text, Button, Grid } from 'grommet';
import { NavLink } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Storage } from "aws-amplify"
import MoncadSpinner from '../components/MoncadSpinner';
import { getCompTeam, getMoreCompTeamMedia } from '../graphql/custom-queries';
import MediaGallery from '../components/MediaGallery';
import { sortMediaByName } from '../utils/utils';

const SingleCompTeam = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [division, setDivision] = useState("");
    const [teamName, setTeamName] = useState([]);
    const [compName, setCompName] = useState([]);
    const [compYear, setCompYear] = useState([]);
    const [result, setResult] = useState([]);
    const [coaches, setCoaches] = useState([]);
    const [athletes, setAthletes] = useState([]);
    const [mediaUrls, setMediaUrls] = useState([]);
    const { id } = useParams();

    const [user, setUser] = useState(false);

    useEffect(async () => {
        const isAuthenticated = async () => {
            try {
                const userResponse = await Auth.currentAuthenticatedUser();
                setUser(userResponse);
            }
            catch {
                setUser(false);
            }
        };
        await isAuthenticated();
    }, []);

    const getInstanceData = async () => {
        try {
            const { data } = await API.graphql({
                query: getCompTeam,
                variables: {
                    id
                },
                authMode: "AWS_IAM"
            });
            setTeamName(data.getCompTeam.team.displayName);
            setCompName(data.getCompTeam.competition.competition.displayName);
            setCompYear(data.getCompTeam.competition.year);
            setDivision(data.getCompTeam.division.displayName);
            setCoaches(data.getCompTeam.coaches.items);
            setAthletes(data.getCompTeam.members.items);
            setResult(data.getCompTeam.result.displayName);
            setIsLoading(false);

            let mediaItems = data.getCompTeam.media.items;
            let nextToken = data.getCompTeam.media.nextToken;

            while (nextToken) {
                const { data: media } = await API.graphql({
                    query: getMoreCompTeamMedia,
                    variables: {
                        id,
                        nextToken
                    },
                    authMode: "AWS_IAM"
                });
                nextToken = media.getCompTeam.media.nextToken;
                mediaItems.push(...media.getCompTeam.media.items);
            }

            mediaItems.sort(sortMediaByName);

            let urlArray = [];
            for (const item of data.getCompTeam.media.items) {
                const url = await Storage.get(item.id);
                urlArray.push({ url, type: item.type, id: item.id });
            }

            setMediaUrls(urlArray);

        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(getInstanceData, []);

    return (
        <Box overflow="auto" style={{ minHeight: "100vh" }} background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <Box gap="small" overflow="auto">
                    {user &&
                        <NavLink to={`/admin/addTeamMembers/${id}`}>
                            <Button primary label="Add members" />
                        </NavLink>}
                    <Box direction="row" align="center" justify="between">
                        <Box>
                            <Text weight="bold" size="large">{teamName}</Text>
                            <Text>{compName} {compYear}</Text>
                            <Text>{division}</Text>
                        </Box>
                        <Box background="white" pad="small" round="small">
                            <Text weight="bold">{result}</Text>
                        </Box>
                    </Box>
                    <Accordion>
                        <AccordionPanel label="Coaches">
                            <Grid pad="small" gap="small" columns="small">
                                {coaches.map(coach =>
                                    <NavLink key={coach.coach.id} to={`/member/${coach.coach.id}`}>
                                        <Text>{coach.coach.displayName}</Text>
                                    </NavLink>
                                )}
                            </Grid>
                        </AccordionPanel>
                        <AccordionPanel label="Athletes">
                            <Grid pad="small" gap="small" columns="small">
                                {athletes.map(athlete =>
                                    <NavLink key={athlete.member.id} to={`/member/${athlete.member.id}`}>
                                        <Text>{athlete.member.displayName}</Text>
                                    </NavLink>
                                )}
                            </Grid>
                        </AccordionPanel>
                    </Accordion>
                    <Box flex>
                        <Heading level="2">Media</Heading>
                        <MediaGallery mediaUrls={mediaUrls} />
                    </Box>
                </Box>
            }

        </Box >
    )
}

export default SingleCompTeam;