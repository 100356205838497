import React, { useEffect, useState } from 'react'
import { API, Storage } from "aws-amplify";
import { Box, Avatar, Heading, Text, Grid, Card, CardHeader, CardBody, Accordion, AccordionPanel } from 'grommet';
import { useParams } from "react-router-dom";
import { getMember, getMoreMemberMedia } from "../../graphql/custom-queries";
import CompTeamCard from '../CompTeamCard';
import placeholder from "../../assets/placeholder.png";
import MoncadSpinner from '../MoncadSpinner';
import MediaGallery from "../MediaGallery";
import { sortMediaByName } from "../../utils/utils";

const SingleMember = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [memberData, setMemberData] = useState({});
    const [sport, setSport] = useState("");
    const [yearsAtClub, setYearsAtClub] = useState({});
    const [profileUrl, setProfileUrl] = useState("");
    const [mediaUrls, setMediaUrls] = useState([]);
    const [teamsCoached, setTeamsCoached] = useState([]);
    const [teamsCompeted, setTeamsCompeted] = useState([]);

    useEffect(() => {
        const getMemberData = async () => {
            try {
                const { data } = await API.graphql({
                    query: getMember,
                    variables: {
                        id
                    },
                    authMode: "AWS_IAM"
                });

                setMemberData(data.getMember);

                if (data.getMember.profilePhoto) {
                    const retrieveUrl = await Storage.get(data.getMember.profilePhoto);
                    setProfileUrl(retrieveUrl);
                }

                let minFoundYear = Infinity;
                let maxFoundYear = 0;
                let foundSports = [];

                let coachedList = [];
                for (const coachedTeam of data.getMember.teamsCoached.items) {
                    const teamData = {
                        id: coachedTeam.team.id,
                        displayName: coachedTeam.team.team.displayName,
                        year: coachedTeam.team.competition.year,
                        sport: coachedTeam.team.team.sport,
                        competition: coachedTeam.team.competition.competition.displayName
                    };
                    if (teamData.year < minFoundYear) minFoundYear = teamData.year;
                    if (teamData.year > maxFoundYear) maxFoundYear = teamData.year;
                    if (!foundSports.includes(teamData.sport)) foundSports.push(teamData.sport);
                    coachedList.push(teamData);
                }

                setTeamsCoached(coachedList);

                let competedList = [];
                for (const compTeam of data.getMember.teams.items) {
                    console.log(compTeam);
                    const teamData = {
                        id: compTeam.team.id,
                        displayName: compTeam.team.team.displayName,
                        year: compTeam.team.competition.year,
                        sport: compTeam.team.team.sport,
                        competition: compTeam.team.competition.competition.displayName
                    };
                    if (teamData.year < minFoundYear) minFoundYear = teamData.year;
                    if (teamData.year > maxFoundYear) maxFoundYear = teamData.year;
                    if (!foundSports.includes(teamData.sport)) foundSports.push(teamData.sport);
                    competedList.push(teamData);
                }

                setTeamsCompeted(competedList);

                let mediaItems = data.getMember.media.items;
                let nextToken = data.getMember.media.nextToken;

                while (nextToken) {
                    const { data: media } = await API.graphql({
                        query: getMoreMemberMedia,
                        variables: {
                            id,
                            nextToken
                        },
                        authMode: "AWS_IAM"
                    });
                    nextToken = media.getMember.media.nextToken;
                    mediaItems.push(...media.getMember.media.items);
                }

                mediaItems.sort(sortMediaByName);

                let urlArray = [];
                for (const item of mediaItems) {
                    const url = await Storage.get(item.media.id);
                    urlArray.push({ url, type: item.media.type, id: item.media.id });
                }

                setMediaUrls(urlArray);

                if (minFoundYear === Infinity) minFoundYear = undefined;
                if (maxFoundYear === 0) maxFoundYear = undefined;

                setSport(foundSports.join(" & "));
                setYearsAtClub({ yearMin: minFoundYear, yearMax: maxFoundYear })
                setIsLoading(false);
            } catch (error) {
                console.log(`Error executing query: ${error}`);
                console.log(`Error executing query: ${JSON.stringify(error)}`);
            }
        };
        getMemberData();
    }, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <>
                    <Box align="center" gap="medium">
                        <Avatar src={profileUrl ? profileUrl : placeholder} background={{ "color": "light-2" }} size="4xl" />
                        <Box align="center">
                            <Heading margin="none">{memberData.displayName}</Heading>
                            <Text size="xlarge" color="grey">{sport}</Text>
                            {memberData.lifeMemberStatus?.isLifeMember &&
                                <Text color="grey" size="large">Inducted as life member in {memberData.lifeMemberStatus.yearInducted}</Text>
                            }
                        </Box>
                    </Box>
                    <Grid gap="small" margin={{ top: "medium" }} columns="medium">
                        {yearsAtClub.yearMin && (
                            <Card background="background" pad="medium">
                                <CardHeader><Text weight="bold">Years At Club</Text></CardHeader>
                                <CardBody>
                                    <Text color="grey">{yearsAtClub.yearMin} - {yearsAtClub.yearMax}</Text>
                                </CardBody>
                            </Card>
                        )}
                        {memberData.committeePositions && (
                            <Card background="background" pad="medium">
                                <CardHeader><Text weight="bold">Committee Positions</Text></CardHeader>
                                <CardBody>
                                    {memberData.committeePositions.map(position => {
                                        return (
                                            <Text key={`${position.year}-${position.displayName}`} color="grey">{position.year} - {position.displayName}</Text>
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )}
                        {memberData.awards && (
                            <Card background="background" pad="medium">
                                <CardHeader><Text weight="bold">Club Awards</Text></CardHeader>
                                <CardBody>
                                    {memberData.awards.map(award => {
                                        return (
                                            <Text key={`${award.year}-${award.displayName}`} color="grey">{award.year} - {award.displayName}</Text>
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )}
                        {/* {memberData.notes && (
                            <Card background="background" pad="medium">
                                <CardHeader><Text weight="bold">Member Notes</Text></CardHeader>
                                <CardBody>
                                    {memberData.notes.map((note, index) => {
                                        return (
                                            <Text key={`note-${index}`} color="grey">{note}</Text>
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )} */}
                    </Grid>
                    <Box>
                        <Heading level="3">Teams</Heading>
                        <Accordion>
                            {teamsCoached.length &&
                                <AccordionPanel label="Coached">
                                    <Grid gap="medium" columns="small" pad="medium">
                                        {teamsCoached.map(team => {
                                            return <CompTeamCard key={team.id} id={team.id} year={`${team.competition} ${team.year}`} displayName={team.displayName} />
                                        })}
                                    </Grid>
                                </AccordionPanel>}
                            {teamsCompeted.length &&
                                <AccordionPanel label="Competed On">
                                    <Grid gap="medium" columns="small" pad="medium">
                                        {teamsCompeted.map(team => {
                                            console.log(team);
                                            return <CompTeamCard key={team.id} id={team.id} year={`${team.competition} ${team.year}`} displayName={team.displayName} />
                                        })}
                                    </Grid>
                                </AccordionPanel>}
                        </Accordion>
                    </Box>
                    <Box flex>
                        <Heading level="2">Media</Heading>
                        <MediaGallery mediaUrls={mediaUrls} />
                    </Box>
                </>}
        </Box>
    )
}

export default SingleMember;