import React, { useEffect, useState } from 'react'
import { Auth, API } from "aws-amplify";
import { Box, Text, Button, Grid } from 'grommet';
import { useParams, NavLink } from "react-router-dom";
import { getTeam } from "../graphql/custom-queries";
import CompTeamCard from "../components/CompTeamCard";
import MoncadSpinner from '../components/MoncadSpinner';

const SingleMember = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [teamData, setTeamData] = useState({});
    const [compTeams, setCompTeams] = useState([]);
    const { id } = useParams();

    const [user, setUser] = useState(false);

    useEffect(async () => {
        const isAuthenticated = async () => {
            try {
                const userResponse = await Auth.currentAuthenticatedUser();
                setUser(userResponse);
            }
            catch {
                setUser(false);
            }
        };
        await isAuthenticated();
    }, []);

    const getTeamData = async () => {
        try {
            const { data } = await API.graphql({
                query: getTeam,
                variables: {
                    id
                },
                authMode: "AWS_IAM"
            });
            setTeamData(data.getTeam);
            setCompTeams(data.getTeam.compTeams.items);
            setIsLoading(false);
        } catch (error) {
            console.log(`Error executing query: ${error}`);
            console.log(`Error executing query: ${JSON.stringify(error)}`);
        }
    }

    useEffect(() => {
        getTeamData();
    }, []);

    return (
        <Box overflow="auto" height="100vh" background={{ "color": "background-back" }} pad="medium">
            {isLoading ?
                <MoncadSpinner /> :
                <>
                    {user &&
                        <NavLink to={`/admin/createCompTeam/${id}`}>
                            <Button primary label="Create Comp Team" />
                        </NavLink>
                    }
                    <Box gap="small">
                        <Box align="center">
                            <Text size="xlarge">{teamData.displayName}</Text>
                            <Text size="medium">{teamData.sport}</Text>
                        </Box>
                        <Grid gap="medium" columns="small" pad="medium">
                            {compTeams.map(team => {
                                return <CompTeamCard key={team.id} id={team.id} year={team.competition.year} displayName={team.competition.competition.displayName} />
                            })}
                        </Grid>
                    </Box>
                </>
            }
        </Box>
    )
}

export default SingleMember;