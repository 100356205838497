import React from 'react'
import { Card, Text } from 'grommet';
import { useHistory } from "react-router-dom";

const TeamCard = (teamDetails) => {
    const history = useHistory();

    const navigateToTeam = (id) => {
        history.push(`/teams/${id}`);
    }

    const { id, displayName } = teamDetails.props;

    return (
        <Card onClick={() => navigateToTeam(id)} justify="center" align="center" width="medium" pad="medium" background={{ "color": "background" }}>
            <Text textAlign="center" weight="bold" size="large">{displayName}</Text>
        </Card>
    )
};

export default TeamCard;