import React, { useState } from "react";
import { Box, Text, Spinner, Layer } from "grommet";
import Dropzone from 'react-dropzone';
import { parse as ReadCSV } from "papaparse";
import { DocumentCsv } from "grommet-icons";
import Popup from "../../components/Popup";
import createMemberRequest from "../../utils/createMemberRequest"

const CreateBulkMembers = () => {

    const [createdID, setCreatedID] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);

    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const addMemberToDB = async (member, parser) => {
        console.log(`Creating member... ${member.data.displayName}`);

        const { data: memberData } = member;

        Object.keys(memberData).forEach(key => {
            if (memberData[key] === "TRUE") memberData[key] = true;
            if (memberData[key] === "FALSE") memberData[key] = false;
        })

        const memberID = await createMemberRequest(memberData);
        setCreatedID(memberID);
    }

    const handleComplete = () => {
        setIsSuccessful(true);
        setTimeout(() => {
            setIsSuccessful(false);
        }, 3000);
    }

    const handleError = () => {
        setIsError(true);
        setTimeout(() => {
            setIsError(false);
        }, 3000);
    }

    const uploadFiles = async acceptedFiles => {
        setIsUploading(true);

        const config = {
            header: true,
            step: addMemberToDB,
            complete: handleComplete,
            error: handleError
        };

        try {
            await ReadCSV(acceptedFiles[0], config);
        }
        catch (error) {
            handleError();
        }

        setIsUploading(false);
    }

    return (
        <Box pad="medium">
            {isUploading && (
                <Layer>
                    <Box
                        align="center"
                        justify="center"
                        gap="small"
                        direction="row"
                        alignSelf="center"
                        pad="large"
                    >
                        <Spinner />
                        <Text>Uploading...</Text>
                    </Box>
                </Layer>
            )}
            <Dropzone accept={'.csv'} multiple={false} onDrop={uploadFiles}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <DocumentCsv />
                            <p>Drop CSV file here, or click to select</p>
                        </div>
                    </section>
                )}
            </Dropzone>
            {createdID && (
                // TODO: Turn into modal
                <Box margin="large">
                    <Text>Successfully created Member</Text>
                    <Text>Member ID: {createdID}</Text>
                </Box>
            )}
            {isSuccessful && (
                <Popup text="Successfully Added Members" success={true} />
            )}
            {isError && (
                <Popup text="An error occurred, check CSV is valid" success={false} />
            )}
        </Box>
    );
}

export default CreateBulkMembers;
